import 'tachyons';

import React, { lazy, ReactElement, Suspense, useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'store';

import { version } from '../package.json';
import styles from './App.module.scss';
import { Splash } from './components/Splash';
import { ToastWrapper } from './components/Toast';
import UnreachableComponent from './components/UnreachableComponent';

const CompleteRegistrationView = lazy(() => import('views/CompleteRegistrationViewNew'));
const CreateAccountView = lazy(() => import('views/CreateAccountView'));
const Dashboard = lazy(() => import('views/DashboardRouter'));
const Testing = lazy(() => import('views/Testing'));
const LoginView = lazy(() => import('views/LoginView'));
const PasswordResetRequestView = lazy(() => import('views/PasswordResetRequestView'));
const PasswordResetView = lazy(() => import('views/PasswordResetView'));
const NotFoundRoute = lazy(() => import('components/NotFoundRoute'));
const VesselsMap = lazy(() => import('views/GlobalDashboard/components/VesselsMap'));

function App(): ReactElement {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (localStorage.getItem('version') !== version) {
      'caches' in window && caches.keys().then(names => names.forEach(name => caches.delete(name)));
      setTimeout(() => {
        localStorage.setItem('version', version);
        window.location.reload();
      }, 1000);
    }
  }, []);

  return (
    <div className={styles.app}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<Splash />}>
            <UnreachableComponent />
            <Routes>
              <Route path="/" element={<LoginView />} />
              <Route path="/create-account" element={<CreateAccountView />} />
              <Route
                path="/complete-registration/:verificationCode"
                element={<CompleteRegistrationView />}
              />
              <Route path="/password-reset-request" element={<PasswordResetRequestView />} />
              <Route path="/password-reset" element={<PasswordResetView />} />
              {/* Routes below checks for session key */}
              <Route path="/dashboard/*" element={<Dashboard />} />
              <Route path={'/dashboard/map-fullscreen'} element={<VesselsMap />} />
              <Route path="*" element={<NotFoundRoute />} />
              <Route path={'/testing'} element={<Testing />} />
            </Routes>
          </Suspense>
        </PersistGate>
      </Provider>
      <ToastWrapper />
    </div>
  );
}

export default hot(module)(App);
